.scrollbar {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar {
      width: 6px;
      background-color: #f5f5f5;
      margin: 15px 0;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(201, 203, 207, 1);
      background-color: #102940;
    }
  }
  .horizontal-scrollbar {
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      background-color: #f5f5f5;
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar {
      height: 6px;
      background-color: #f5f5f5;
      margin: 15px 0;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(201, 203, 207, 1);
      background-color: #102940;
    }
  }
  
  html,
  body {
    height: 100%;
    width:100%;
  }
  body {
    margin: 0;
    font-family: "Open Sans", sans-serif !important;
  }
  .clickable {
    cursor: pointer !important;
  }
  .nav-tabs {
    border-bottom: 0 !important;
  }
  .nav-link {
    color: #102940 !important;
    border-bottom: 1px solid;
    border-color: rgb(227, 227, 228, 0.5) !important;
    &:hover {
      background-color: rgb(227, 227, 228, 0.3) !important;
    }
    &.active {
      background-color: rgb(227, 227, 228, 0.3) !important;
      border-bottom: 0 !important;
    }
  }
  .mat-mdc-chip-listbox {
    &[aria-orientation="vertical"] {
      .mdc-evolution-chip-set__chips {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .mat-mdc-dialog-container .mdc-dialog__surface {
    overflow-x: hidden !important
  }
  
  .w-fit-content {
    width: fit-content;
  }
  
  .mat-mdc-chip {
    background-color: white !important;
    border: 1px solid rgb(227, 227, 228, 1);
    &.active {
      background-color: #618baa !important;
      .mat-mdc-chip-action-label {
        color: white !important;
      }
    }
    .mdc-evolution-chip__cell {
      justify-content: center;
    }
    &:not(.disabled) .mdc-evolution-chip__cell > * {
      cursor: pointer;
    }
  }
  .mat-mdc-menu-content,
  .mat-mdc-select-panel {
    padding: 0 !important;
  }
  .mat-mdc-option {
    padding: 0 0.5rem !important;
  }
  .mat-mdc-select-panel-above {
    width: fit-content !important;
  }
  .mat-mdc-form-field {
    width: 100%;
  }
  .leader-line {
    z-index: 1000;
    cursor: pointer;
  }
  body .border {
    &-gray {
      border-color: #d5d5d5 !important;
    }
    &-dotted {
      border-style: dotted !important;
    }
    &-dashed {
      border-style: dashed !important;
    }
    &-width {
      &-1 {
        border-width: 1px !important;
      }
      &-2 {
        border-width: 2px !important;
      }
      &-3 {
        border-width: 3px !important;
      }
      &-4 {
        border-width: 4px !important;
      }
      &-5 {
        border-width: 5px !important;
      }
    }
  }
  // .mat-mdc-form-field-subscript-wrapper {
  //   display: none;
  // }
  .mat-mdc-dialog-container .mdc-dialog__surface {
    overflow: hidden;
  }
  .mat-mdc-table .mdc-data-table__row:not(.example-detail-row) {
    height: 32px;
    .mat-mdc-icon-button {
      height: 32px;
      width: 32px;
      padding: 4px;
    }
  }

  .mat-mdc-select-disabled .mat-mdc-select-value {
    color:black !important
  }
  .mat-mdc-select-disabled {
    .mat-mdc-select-arrow-wrapper {
        display: none;
    }
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: white !important; 
  }

@media only screen and (max-width: 680px){
  .cdk-overlay-pane {
    max-width: 100vw !important;
    overflow-y: scroll !important;
  }
  .mat-expansion-panel-body {
    padding: 0px !important
  }
}
